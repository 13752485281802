.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 20px;
    width: 900px;
    height: 400px;
    position: absolute;
    top: calc(50% - 60px); /* 60px for the size of the scrolling icons */
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px 0 var(--dark-text-color);
}
.picture-and-name{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-image: linear-gradient(to right, var(--tertiary-color), var(--primary-color));
    border-radius: 10px;
    transition: 0.3s ease;
    
}
.picture-and-name:hover{
    transform: translateY(-5px);
    box-shadow: 0 0 10px 0 var(--dark-text-color);
}
.headshot{
    width: 300px;
    height: 300px;
}
.name{
    font-size: 40px;
    color: var(--light-text-color);
    font-weight: bold;
    margin: 20px;
}
.bio{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    color: var(--light-text-color);
    font-size: 20px;
    padding: 10px;
    text-align: center;
}
h1{
    margin: 0;
}
p{
    margin: 0;
}

.socials{
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.social-icon{
    margin-left: 10px;
    margin-right: 10px;
    font-size: 50px;
    color: var(--primary-color);
    transition: 0.3s ease;
}

.social-icon:hover{
    transform: translateY(-5px);
    color: var(--light-text-color);
}


@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
}
.icons-container{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--secondary-color);
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 0 10px 0 var(--dark-text-color);
}
.scrolling-icons {
    display: flex;
    width: 100%;
}
.icons {
    display: flex;
    animation: scroll 45s linear infinite;
    width: 100%;
}
.moving-icon {
    margin-left: 20px;
    font-size: 60px;
    color: var(--primary-color);
    transition: 0.3s ease;
    cursor: pointer;
}
.moving-icon:hover{
    transform: translateY(-5px);
    color: var(--light-text-color);
}

/* mobile layout */
@media (max-width: 768px) {
    .icons-container{
        display: none;
    }
    .about{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80vw;
        height: auto;
        padding-bottom: 5px;
        top: 460px;
    }
    .name{
        font-size: 32px;
    }
    .bio{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 0;
    }

    .socials{
        margin-top: 20px;
    }

    h1{
        display: none;
    }
}