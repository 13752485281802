nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: var(--primary-color);
    height: 55px;
    gap: 100px;
}

.navLink:hover{
    transform: translateY(-5px);

}

.navLink {
    color: var(--light-text-color);
    text-decoration: none;
    padding: 10px;
    transition: transform 0.3s ease;
    font-size: 20px;
}

.active{
    font-weight: bold;
    transition: transform 0.3s ease;
}

/* mobile */
.dropdownMenu {
    display: none;
}
  
.menu-button {
    display: none;
}

.page-title{
    display: none;
}
  
  @media (max-width: 768px) {
    .page-title{
        display: block;
        color: var(--light-text-color);
        font-size: 18px;
        font-weight: bold;
    }
    .dropdownMenu {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: var(--primary-color);
      width: 100%;
      gap: 10px;
      padding: 10px;
    }
    .dropdownMenu a{
        color: var(--light-text-color);
        text-decoration: none;
    }
    .dropdownContent {
      display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-top: 100px;
    }
  
    .menu-button {
      display: block;
      background: none;
      border: none;
      color: var(--light-text-color);
      position: absolute;
        top: 10px;
        left: 10px;
    }
  
    .navLink {
      display: none;
    }

    nav{
        flex-direction: column;
        height: 35px;
    }
  }