.card-front, .card-back {
    width: 450px; 
    height: 350px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    background-color: var(--secondary-color);
    margin: 20px;
    box-shadow: 0 0 10px 0 var(--dark-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease;
}

.card-front:hover, .card-back:hover{
    box-shadow: 0 0 20px 0 var(--dark-text-color);
}

.card-front-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
    
.card-front img{
    width: auto;
    height: 210px;
    border-radius: 5px;
    transition: 0.3s ease;
    background-image: linear-gradient(to right, var(--tertiary-color), var(--primary-color));
    padding: 10px;
}

.card-front img:hover {
    transform: scale(1.05);
}

.card-front a{
    text-decoration: none;
    color: var(--light-text-color);
    font-size: 30px;
    font-weight: bold;
    padding: 10px;
    transition: 0.3s ease;
}

.card-front a:hover{
    color: var(--dark-text-color); 
}

.projects{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    color: var(--light-text-color);
    justify-content: space-evenly;
}


.description-text{
    text-align: center;
    font-size: 18px;
    color: var(--light-text-color);
    line-height: 1.25;
    padding-top: 30px;
}

.tech-icon-wrapper{
    position: absolute;
    bottom: 52px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.tech-icon{
    width: 30px;
    height: 30px;
    color: var(--primary-color);
    transition: 0.3s ease;
    cursor: pointer;
}
.tech-icon:hover{
    transform: translateY(-5px);
    color: var(--light-text-color);
}

.card-back{
    justify-content: space-between;
}

/* mobile */
@media (max-width: 768px) {
    .card-front, .card-back {
        width: 80vw;
        height: 335px;
    }
    .card-front a{
        font-size: 25px;
    }

    .tech-icon{
        width: 20px;
        height: 20px;
    }

    .description-text{
        padding-top: 0;
    }

    .card-front img{
        width: 78vw;
        height: auto;
    }
}