.content{
  padding-top: 55px;
  height: calc(100vh - 65px);
  overflow-y: auto;
}
body {
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', 'sans-serif';
  background-color: var(--background-color);
  color: var(--dark-text-color);
  height: 100vh;
  width: 100vw;
}
